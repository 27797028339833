import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFilePdf, FaCheckCircle } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import Marquee from "react-fast-marquee";
import subwayVideo from "../assets/Subwaysurfers.mp4";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "./Header";

const HomeScreen = ({
  videoId,
  file,
  setFile,
  apiLoader,
  isUploading,
  setIsUploading,
  error,
  setVideoId,
  setApiLoader,
  setApiResponse,
}) => {
  const [dragging, setDragging] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const base64ToBlob = (base64) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: "pdf" });
  };

  const sendFileToApi = async (base64File, fileType, fileName) => {
    try {
      setIsUploading(true);
      const fileBlob = base64ToBlob(base64File, fileType);

      const formData = new FormData();
      formData.append("pdf_file", fileBlob);

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/upload-pdf`,
        formData,
        {
          headers: { token: localStorage.getItem("accessToken") },
        }
      );

      if (response.data.status) {
        localStorage.setItem("current_video_id", response.data.data.video_id);
        setVideoId(response.data.data.video_id);
        setApiResponse(response.data);
        setApiLoader(true);
        setLoader(false);
      }
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoader(false);
      setIsUploading(false);
    }
  };

  useEffect(() => {
    const base64File = localStorage.getItem("uploadedFile");
    if (base64File) {
      setLoader(true);
      const fileName = localStorage.getItem("fileName");
      const fileType = localStorage.getItem("fileType");

      setIsUploading(true);
      sendFileToApi(base64File, fileType, fileName);
    }
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-center",
      });
    }
  }, [error]);

  useEffect(() => {
    if (apiLoader) {
      const timeoutId = setTimeout(() => {
        setIsUploading(false);
        if (localStorage.getItem("accessToken")) {
          navigate(`/result?videoId=${videoId}`);
        } else {
          navigate("/login");
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [apiLoader]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type == "application/pdf") {
      setIsUploading(true);
      setShow(true);
      console.log("file without drag drop", file);
      if (!localStorage.getItem("accessToken")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          localStorage.setItem("uploadedFile", reader.result);
        };
        reader.readAsDataURL(file);
      }
      setFile(file);
      setShow(false);
    } else {
      toast.warning("Please upload a valid PDF file.", {
        position: "top-center",
      });
    }
    // }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    if (file && file.type == "application/pdf") {
      setDragging(false);
      setIsUploading(true);
      if (!localStorage.getItem("accessToken")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          localStorage.setItem("uploadedFile", reader.result);
        };
        reader.readAsDataURL(file);
      }
      setFile(file);
    } else {
      toast.warning("Please upload a valid PDF file.", {
        position: "top-center",
      });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Container className="py-4">
        <Header />
        <div className="row d-flex align-items-center  top_margin">
          {/* <div className="col-0 col-md-1"></div> */}
          <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start image_section_handling">
            <div className="image_main">
              <video autoPlay loop muted>
                <source src={subwayVideo} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="responsive_handling_block col-12 col-md-7 text-center text-md-start d-flex flex-column justify-content-center mt-5 mt-md-0">
            <Marquee
              speed={30}
              gradient={false}
              className="mb-3 marquee_handling"
            >
              <div className="d-inline-block mx-3 fs-4 fw-medium">
                <FaCheckCircle className=" me-2" />
                Flashcards
              </div>
              <div className="d-inline-block mx-3 fs-4 fw-medium">
                <FaCheckCircle className=" me-2" />
                Quizzes
              </div>
              <div className="d-inline-block mx-3 fs-4 fw-medium">
                <FaCheckCircle className=" me-2" />
                100+ Languages
              </div>
              <div className="d-inline-block mx-3 fs-4 fw-medium">
                <FaCheckCircle className=" me-2" />
                Legal to Use
              </div>
            </Marquee>

            <h2 className=" mb-3 hero_heading_text">
              Learn Anything With <span className="text_handling">Video</span>{" "}
            </h2>
            <p className="mb-4 hero_sub_text">
              Turn a PDF, URL, or audio into
              <span className="bg-black px-1 text-white">
                a short video that helps you learn
              </span>
            </p>

            <div
              className={`input_box  text-center ${dragging ? "bg-light" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <input
                type="file"
                accept="application/pdf"
                className="d-none"
                onChange={handleFileChange}
                id="fileInput"
                disabled={isUploading}
              />
              <label htmlFor="fileInput" className="d-block">
                {isUploading ? (
                  <div className="uploading-animation">
                    <p className="mb-0 d-flex justify-content-center justify-content-center">
                      <Spinner size="lg" /> &nbsp; Summarizing your PDF...
                    </p>
                  </div>
                ) : (
                  <>
                    <MdOutlineFileUpload
                      className="text-black mb-2"
                      style={{ fontSize: "2rem" }}
                    />
                    <p className="text-black mb-0">Drag and drop Any PDF</p>
                  </>
                )}
              </label>
            </div>
            {/* <div>
              <Button className="button_custom_color " varient="dark">
                Add a Link
              </Button>
            </div> */}
          </div>
          <div className="col-1"></div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default HomeScreen;
