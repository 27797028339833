// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCrDpZOI6-H5wJVAQyR1qPzhO6LcLlVuPM",
  authDomain: "briannumb-66aa7.firebaseapp.com",
  projectId: "briannumb-66aa7",
  storageBucket: "briannumb-66aa7.firebasestorage.app",
  messagingSenderId: "92949641439",
  appId: "1:92949641439:web:d4f1de1180b8f22059116a",
  measurementId: "G-1GQTB5PVC4",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// auth.settings.appVerificationDisabledForTesting = true;
export default app;
