import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import loginImg from "../assets/phone.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import VerificationUi from "./VerificationUI";
const Login = () => {
  const [phone, setPhone] = React.useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [toggleState, setToggleState] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  async function setupRecaptcha(number) {
    try {
      if (Window.recaptchaVerifier) {
        Window.recaptchaVerifier.clear();
        const previousRecaptchaContainer = document.getElementById(
          "recaptcha-container"
        );
        if (previousRecaptchaContainer) {
          previousRecaptchaContainer.remove();
        }
        const newRecaptchaContainer = document.createElement("div");
        newRecaptchaContainer.id = "recaptcha-container";
        newRecaptchaContainer.className = "ml-2 recaptcha-container";
        document
          .getElementById("repactcha-main-div")
          .append(newRecaptchaContainer);
      }
      Window.recaptchaVerifier = await new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
      setLoader(true);
      console.log("signInWithPhoneNumber");

      const result = await signInWithPhoneNumber(
        auth,
        phone,
        Window.recaptchaVerifier
      );
      setConfirmationResult(result);
      console.log("result", result);
      setToggleState(true);
      console.log("OTP sent", result);

      setLoader(false);
    } catch (err) {
      console.error("Error:", err.message);
      return err.message;
    }
  }

  const loginFn = async (event) => {
    event.preventDefault();
    if (phone) {
      await setupRecaptcha(phone);
      setLoader(false);
    } else {
      toast.error("Please enter your mobile number to continue.", {
        position: "top-center",
      });
    }
  };

  const handlSetPhone = (e) => {
    console.log(e);
    setPhone(e);
  };
  return (
    <>
      {!toggleState ? (
        <div
          className="p-3 p-sm-5 p-md-0"
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // background: "linear-gradient(to bottom right, #f0f4ff, #d9e4f7)",
          }}
        >
          <ToastContainer />
          <Container>
            <Row
              className="shadow-lg  overflow-hidden flex-column-reverse flex-md-row"
              style={{
                maxWidth: "900px",
                margin: "0 auto",
                borderRadius: "24px",
              }}
            >
              <Col
                md={6}
                className="p-3 p-sm-5 d-flex flex-column justify-content-"
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px 0 0 15px",
                }}
              >
                <h3 className="fw-bold mb-3">
                  BrainNumb{" "}
                  <span className="text-secondary fw-light">AI Notes</span>
                </h3>
                <h3 className="mb-3" style={{ fontWeight: "600" }}>
                  Login with Your Phone
                </h3>
                <p className="text-muted mb-1">
                  Access your world with just your number. Secure, simple, and
                  seamless.
                </p>
                <Form className="mt-3" onSubmit={loginFn}>
                  <div className="mb-4">
                    <PhoneInput
                      id="number-input"
                      international
                      defaultCountry="US"
                      value={phone}
                      onChange={(e) => handlSetPhone(e)}
                      placeholder="Enter Your Phone Number"
                      disabled={loader ? true : false}
                    />
                  </div>

                  <div id="repactcha-main-div">
                    <div
                      id="recaptcha-container"
                      className="ml-2   recaptcha-container"
                    ></div>
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100 nav_button_custom_color"
                    disabled={loader}
                  >
                    {!loader ? "Continue" : <Spinner size="sm" />}
                  </Button>
                </Form>
              </Col>
              <Col md={6} className="p-2">
                <img src={loginImg} alt="login" className="login_form_image" />
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <VerificationUi
          result={confirmationResult}
          setConfirmationResult={setConfirmationResult}
          phone={phone}
          loginFn={loginFn}
          setToggleState={setToggleState}
        />
      )}
    </>
  );
};

export default Login;
