import React, { useEffect, useState } from "react";
import "./App.css";
import HomeScreen from "./components/HomeScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Pricing from "./components/Pricing";
import ResponsePage from "./components/ResponsePage";
import VerificationUI from "./components/VerificationUI";
import "react-phone-number-input/style.css";
import axios from "axios";
import NotFound from "./components/NotFound";
function App() {
  const [file, setFile] = useState(null);
  const [token, setToken] = useState(null);
  const [apiResponse, setApiResponse] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [videoId, setVideoId] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("accessToken"));
  }, []);

  const MdUploadFile = async () => {
    setApiLoader(false);
    setIsUploading(true);
    setTimeout(() => {
      if (!localStorage.getItem("accessToken")) {
        if (localStorage.getItem("payment-status") == 0) {
          window.location.href = "/pricing";
        } else {
          window.location.href = "/login";
        }
      } else if (localStorage.getItem("payment-status") == 0) {
        window.location.href = "/pricing";
      }
    }, 2000);

    const formDataToSend = new FormData();
    formDataToSend.append("pdf_file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/upload-pdf`,
        formDataToSend,
        {
          headers: { token: localStorage.getItem("accessToken") },
        }
      );
      if (response.data.status) {
        localStorage.setItem("current_video_id", response.data.data.video_id);
        setVideoId(response.data.data.video_id);
        setApiResponse(response.data);
        setApiLoader(true);
      }
    } catch (error) {
      setApiLoader(true);
      setTimeout(() => {
        setIsUploading(false);
      }, 1000);
      console.log("error whilte uploading image", error.message);
      // setError(error.message);
      setFile(null);
    }
  };

  useEffect(() => {
    setApiLoader(false);

    if (file) {
      MdUploadFile();
    }
  }, [file]);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <HomeScreen
                videoId={videoId}
                file={file}
                setFile={setFile}
                apiLoader={apiLoader}
                setApiLoader={setApiLoader}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                error={error}
                setVideoId={setVideoId}
                setApiResponse={setApiResponse}
              />
            }
          />
          <Route
            path="/login"
            element={token ? <Navigate to="/" /> : <Login />}
          />
          <Route path="/pricing" element={<Pricing />} />

          <Route
            path="/result"
            element={
              localStorage.getItem("accessToken") ? (
                <ResponsePage
                  apiResponse={apiResponse}
                  setApiLoader={setApiLoader}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route path="/verify-user" element={<VerificationUI />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
