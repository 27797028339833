// src/components/CheckoutForm.js
import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const PaymentForm = ({ selectedPlan, success }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setErrorMessage("");
  }, [name, addressLine1, addressLine2, country, city, zipCode]);

  const apiCalling = async (id) => {
    try {
      const paymentType =
        selectedPlan == "One Generation"
          ? "single"
          : selectedPlan == "Unlimited Monthly"
          ? "monthly"
          : selectedPlan == "One Year"
          ? "year"
          : "";

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/make-payment`,
        {
          pm_id: id,
          plan: paymentType,
        },
        {
          headers: {
            token: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data.status) {
        localStorage.setItem("payment-status", true);
        console.log("card response", response.data.message);
        success(response.data.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response?.data.error);
      console.log("error", error);
    }
  };

  const DARK_CARD_OPTIONS = {
    // style: {
    //   base: {
    //     color: "black",
    //     "::placeholder": {
    //       background: "white",
    //     },
    //     ":-webkit-autofill": {
    //       background: "white",
    //     },
    //   },
    // },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    if (!stripe || !elements) {
      return;
    }
    if (!name) {
      setErrorMessage("Name field are Required");
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name,
      },
    });

    if (error) {
      console.log("stripe error", error);
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      // console.log("PaymentMethod created:", paymentMethod);

      apiCalling(paymentMethod.id);
    }
  };

  return (
    <div>
      {/* <ToastContainer position="top-center" /> */}
      <form onSubmit={handleSubmit} className="  bg-white rounded ">
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            className="name_field"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="cardNumber" className="form-label">
            Card Number
          </label>
          <div className="p-2 border rounded">
            <CardNumberElement
              onChange={(event) => {
                setErrorMessage("");
              }}
              // className="form-control"
              options={DARK_CARD_OPTIONS}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="expiryDate" className="form-label">
              Expiry Date
            </label>
            <div className="p-2 border rounded">
              <CardExpiryElement
                // className="form-control"
                options={DARK_CARD_OPTIONS}
                onChange={(event) => {
                  setErrorMessage("");
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <label htmlFor="cvc" className="form-label">
              CVC
            </label>
            <div className="p-2 border rounded">
              <CardCvcElement
                // className="form-control"
                options={DARK_CARD_OPTIONS}
                onChange={(event) => {
                  setErrorMessage("");
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="addressLine1" className="form-label">
              Address Line 1
            </label>
            <input
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              id="addressLine1"
              className="form-control"
            />
          </div>
          <div className="col-6">
            <label htmlFor="addressLine2" className="form-label">
              Address Line 2
            </label>
            <input
              type="text"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              id="addressLine2"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="city" className="form-label">
              City
            </label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              id="city"
              className="form-control"
            />
          </div>
          <div className="col-6">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <input
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              id="country"
              className="form-control"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="zipCode" className="form-label">
            Zip Code
          </label>
          <input
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            id="zipCode"
            className="form-control"
          />
        </div> */}

        {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
        <button
          type="submit"
          disabled={!stripe || loading}
          className="w-50 btn bg-black text-white"
        >
          {loading ? "Processing..." : "Pay Now"}
        </button>
      </form>
    </div>
  );
};

export default PaymentForm;
