import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Header = () => {
  const navigate = useNavigate();

  const logOutFn = () => {
    toast.success("logout successfully", {
      position: "top-center",
    });
    setTimeout(() => {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    }, 1000);
  };
  return (
    <div>
      <ToastContainer />
      <nav className="mb-5 d-flex justify-content-between align-items-center">
        <Link to="/" className="nav-link">
          <h1 className="fw-bold responsive_header">
            BrainNumb <span className="text-secondary fw-light">AI Notes</span>
          </h1>
        </Link>
        <div>
          {localStorage.getItem("accessToken") ? (
            <Button className="nav_button_custom_color" onClick={logOutFn}>
              Logout
            </Button>
          ) : (
            <Button
              className="nav_button_custom_color"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
