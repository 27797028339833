import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentContainer from "./PaymentForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const StripeContainer = ({ selectedPlan, success }) => {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <PaymentContainer selectedPlan={selectedPlan} success={success} />
      </Elements>
    </div>
  );
};

export default StripeContainer;
