import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="vh-100 d-flex justify-content-center align-items-center flex-column">
        {" "}
        <h1>Oops... page not found</h1>
        <Button
          className="bg-black text-white"
          onClick={() => {
            navigate("/");
          }}
          variant="Secondary"
        >
          Home
        </Button>
      </div>
    </Container>
  );
};

export default NotFound;
