import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import loginImg from "../assets/otp.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
const OTPVerification = ({
  result,
  phone,
  loginFn,
  setToggleState,
  setConfirmationResult,
}) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [response, setResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [timeLeft, setTimeLeft] = useState(60); // 60 seconds timer
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const getToken = async (token) => {
    try {
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/login-user`,
        {
          mobile_number: phone,
          tokenid: token,
        }
      );
      console.log("apiResponse", apiResponse.data.data.is_payment);
      if (apiResponse.data.status) {
        toast.success("You are successfully logged in", {
          position: "top-center",
        });
        localStorage.setItem("accessToken", apiResponse.data.data.token);
        localStorage.setItem(
          "payment-status",
          apiResponse.data.data.is_payment
        );

        setTimeout(() => {
          if (apiResponse.data.data.is_payment) {
            window.location.href = "/";
          } else {
            window.location.href = "/pricing";
          }
        }, 1000);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error.message, {
        position: "top-center",
      });
    }
  };

  const verifyFn = async (e) => {
    e.preventDefault();
    const otpNew = parseFloat(otp.toString().replace(/,/g, ""));
    const findOtp = otp.filter((element) => {
      return element !== "";
    });
    console.log("findOtp", findOtp.length);
    if (findOtp.length < 6) {
      toast.error("The OTP must be exactly 6 digits.", {
        position: "top-center",
      });
      return;
    }
    if (otpNew) {
      try {
        setLoader(true);
        console.log("result", otpNew);
        const res = await result.confirm(otpNew);
        setResponse(res._tokenResponse.idToken);
        console.log("firebase token", res._tokenResponse.idToken);
        getToken(res._tokenResponse.idToken);
      } catch (error) {
        console.log("error", error.message);
        setLoader(false);
        toast.error("Invalid Verification code", {
          position: "top-center",
        });
      }
    } else {
      toast.error("Please enter the One-Time Password (OTP) to proceed.", {
        position: "top-center",
      });
    }
  };

  async function setupRecaptcha(number) {
    try {
      if (Window.recaptchaVerifier) {
        Window.recaptchaVerifier.clear();
        const previousRecaptchaContainer = document.getElementById(
          "recaptcha-container"
        );
        if (previousRecaptchaContainer) {
          previousRecaptchaContainer.remove();
        }
        const newRecaptchaContainer = document.createElement("div");
        newRecaptchaContainer.id = "recaptcha-container";
        newRecaptchaContainer.className = "ml-2 recaptcha-container";
        document
          .getElementById("repactcha-main-div")
          .append(newRecaptchaContainer);
      }
      Window.recaptchaVerifier = await new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
      // setLoader(true);
      console.log("signInWithPhoneNumber");

      const result = await signInWithPhoneNumber(
        auth,
        phone,
        Window.recaptchaVerifier
      );
      setConfirmationResult(result);
      console.log("result", result);
      setToggleState(true);
      console.log("OTP sent", result);

      setLoader(false);
    } catch (err) {
      console.error("Error:", err.message);
      return err.message;
    }
  }

  const sendAgainCode = async (e) => {
    otp.fill("");
    try {
      await setupRecaptcha(phone);
      setIsTimerActive(true);
      setTimeLeft(60);
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    let timer;
    if (isTimerActive && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
    } else if (timeLeft === 0) {
      setIsTimerActive(false); // Stop the timer
    }

    return () => clearTimeout(timer);
  }, [isTimerActive, timeLeft]);

  const navigateFn = () => {
    setToggleState(false);
  };

  return (
    <div
      className="p-1  p-md-0"
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ToastContainer />
      <Container>
        <Row
          className="shadow-lg overflow-hidden flex-column-reverse flex-md-row"
          style={{ maxWidth: "900px", margin: "0 auto", borderRadius: "24px" }}
        >
          <Col
            md={6}
            className="p-3 p-sm-3 p-md-4 p-lg-5 d-flex flex-column justify-content-center"
            style={{ backgroundColor: "white", borderRadius: "15px 0 0 15px" }}
          >
            <h3 className="fw-bold mb-3">
              BrainNumb{" "}
              <span className="text-secondary fw-light">AI Notes</span>
            </h3>
            <h3 className="mb-3" style={{ fontWeight: "600" }}>
              Verify your account
            </h3>
            <p className="text-muted position-relative ">
              <span>
                {" "}
                Enter the 6-digit verification code sent to your phone.
              </span>
              <span
                className="text-primary "
                style={{ gap: "5px" }} // Optional: Add spacing between phone number and icon
              >
                {phone}

                <MdModeEditOutline
                  onClick={() => {
                    navigateFn();
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-primary mb-1"
                />
              </span>
            </p>
            <Form className=" justify-content-between mt-3" onSubmit={verifyFn}>
              <div className="d-flex justify-content-between pag-1">
                {[...Array(6)].map((_, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    maxLength="1"
                    className="text-center responsive_input"
                    value={otp[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    style={{
                      width: "50px",
                      height: "50px",
                      fontSize: "1.5rem",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                    }}
                  />
                ))}
              </div>
              <div id="repactcha-main-div">
                <div
                  id="recaptcha-container"
                  className="ml-2   recaptcha-container"
                ></div>
              </div>
              <Button
                type="submit"
                className="mt-4 w-100 nav_button_custom_color"
                disabled={loader}
              >
                {!loader ? "Verify Now" : <Spinner size="sm" />}
              </Button>
            </Form>
            <p className="mt-3 text-muted">
              {isTimerActive ? (
                <span className="text-muted">
                  Resend Code in{" "}
                  <span className="text-black" style={{ cursor: "default" }}>
                    0:{String(timeLeft).padStart(2, "0")}
                  </span>
                </span>
              ) : (
                <>
                  Didn't Receive the OTP?
                  <span
                    onClick={sendAgainCode}
                    className="text-primary fw-semibold"
                    style={{ cursor: "pointer" }}
                  >
                    Send again
                  </span>
                </>
              )}
            </p>
          </Col>
          <Col md={6} className="p-2">
            <img src={loginImg} alt="verify" className="login_form_image" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OTPVerification;
