import React, { useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Badge,
  Alert,
  Navbar,
} from "react-bootstrap";
import {
  FaDollarSign,
  FaRegClock,
  FaInfinity,
  FaCheckCircle,
} from "react-icons/fa"; // You can use any icons you prefer
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeContainer from "./StripeContainer";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Pricing = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [price, setPrice] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const handleSelectPlan = (plan, price) => {
    setSelectedPlan(plan);
    setPrice(price);
  };

  const handleContinue = () => {
    handleShow();
    if (selectedPlan && price) {
      if (localStorage.getItem("accessToken")) {
      } else {
        navigate("/login");
      }
    }
  };

  const success = (message) => {
    console.log("inside success message", message);
    toast.success(message, {
      position: "top-center",
    });
    handleClose();
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <Container className="py-4">
      <ToastContainer />
      <Header />
      <Container className="">
        <div className="text-center mb-5">
          <h1 className="font-weight-bold mb-3">Simple, Transparent Pricing</h1>
          <p className="mx-auto" style={{ maxWidth: "700px" }}>
            Choose the plan that best fits your needs. Whether it's a one-time
            generation or an ongoing subscription, we have you covered.
          </p>
        </div>

        <Row className="g-4">
          <Col md={4}>
            <Card
              className={`py-4 shadow-lg pricing_card secondary rounded-lg  ${
                selectedPlan === "One Generation" ? "border_line" : ""
              }`}
              style={{ height: "100%" }}
            >
              <Card.Body className="text-center">
                <FaDollarSign size={50} className=" mb-3" />
                <Card.Title className="font-weight-bold mb-3">
                  One Generation
                </Card.Title>
                <h2 className=" mb-1">$1.99</h2>
                <p className="text-muted mb-3">/one time</p>
                <Card.Text className="mb-3">
                  Perfect for a single use case.One generation of content for a
                  low cost.
                </Card.Text>
                <Button
                  onClick={() => handleSelectPlan("One Generation", 1.99)}
                  className={`${
                    selectedPlan === "One Generation"
                      ? "nav_button_custom_color"
                      : "not_slect_btn"
                  }`}
                >
                  Select Plan
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card
              className={`py-4 shadow-lg pricing_card  ${
                selectedPlan === "Unlimited Monthly" ? "border_line" : ""
              }`}
              style={{ height: "100%" }}
            >
              <Card.Body className="text-center">
                <FaInfinity size={50} className=" mb-3" />
                <Card.Title className="font-weight-bold mb-3">
                  Unlimited Monthly
                </Card.Title>
                <h2 className=" mb-1">$9.99</h2>
                <p className="text-muted mb-3">/month</p>
                <Card.Text className="mb-3">
                  Enjoy unlimited generations each month, ideal for frequent
                  use.
                </Card.Text>
                <Button
                  onClick={() => handleSelectPlan("Unlimited Monthly", 9.99)}
                  className={`${
                    selectedPlan === "Unlimited Monthly"
                      ? "nav_button_custom_color"
                      : "not_slect_btn"
                  }`}
                >
                  Select Plan
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card
              className={`py-4 shadow-lg pricing_card ${
                selectedPlan === "One Year" ? "border_line" : ""
              }`}
              style={{ height: "100%" }}
            >
              <Card.Body className="text-center">
                <FaRegClock size={50} className=" mb-3" />
                <Card.Title className="font-weight-bold mb-3">
                  One Year
                </Card.Title>
                <h2 className=" mb-1">$34.99</h2>
                <p className="text-muted mb-3">/year</p>
                <Card.Text className="mb-3">
                  Best value! Get an entire year of unlimited generations at a
                  reduced rate.
                </Card.Text>
                <Button
                  onClick={() => handleSelectPlan("One Year", 34.99)}
                  className={`${
                    selectedPlan === "One Year"
                      ? "nav_button_custom_color"
                      : "not_slect_btn"
                  }`}
                >
                  Select Plan
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="text-center mt-5">
          <Button
            className="bg-black text-white nav_button_custom_color"
            variant={selectedPlan ? "" : "secondary"}
            size="lg"
            onClick={handleContinue}
            disabled={!selectedPlan}
          >
            Continue
          </Button>
        </div>
      </Container>
      <Modal
        size="xl"
        backdrop="static"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row flex-column-reverse flex-lg-row ">
            <div className="col-12 col-lg-7 px-4 pb-3">
              <h3 className="fw-bold mb-4">
                BrainNumb{" "}
                <span className="text-secondary fw-light">AI Notes</span>
              </h3>
              <div className="">
                <h3 className="">Final Step, make the payment.</h3>
                <p className="text-muted pe-4">
                  To finalize your subscription, kindly complete your payment
                  using a valid credit card.
                </p>
              </div>
              <StripeContainer selectedPlan={selectedPlan} success={success} />
            </div>

            <div className="col-12 col-lg-5 p-4">
              <h5 className="text-muted">You've to pay</h5>
              <div className="mb-4 fs-2 d-flex align-items-center">
                <FaDollarSign /> {price}
              </div>
              <div className="mb-5">
                <div className="fs-5 d-flex align-items-center fw-medium">
                  <FaCheckCircle className="text-success" color="success" />
                  &nbsp; <span>Payment & Invoice</span>
                </div>
                <p className="text-muted fs-6">
                  We'll worry about all the transaction and payments. Your can
                  sit back and relax while you make
                </p>
              </div>
              <div>
                <div className="fs-5 d-flex align-items-center fw-medium">
                  <FaCheckCircle className="text-success" color="success" />
                  &nbsp; <span>Dummy </span>
                </div>
                <p className="text-muted fs-6">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
                  velit quo error ad
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Pricing;
