import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { FaDownload, FaPlay, FaHourglass } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { CiPause1 } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import Header from "./Header";
const ResponsePage = ({ apiResponse = {}, setApiLoader }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [words, setWords] = useState([]);
  const [audioFile, setAudioFile] = useState("");
  const [videoIdData, setVideoIdData] = useState("1");
  const [progress, setProgress] = useState("");
  const [videoData, setVideoData] = useState("");
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const subtitleTimerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSubtitleVisible, setIsSubtitleVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const [audioKey, setAudioKey] = useState("");
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoId = searchParams.get("videoId");
  const apiRef = useRef(null);
  const navigate = useNavigate();
  let intervalElement;
  useEffect(() => {
    localStorage.removeItem("uploadedFile");
  }, []);

  useEffect(() => {
    if (apiResponse.data) {
      setOptions(apiResponse.data.background_videos || []);
      setAudioFile(apiResponse.data.audio_path || "");
      setWords(apiResponse.data.subtitles || []);
      setSelectedOption(apiResponse.data.background_videos || "");
      setVideoData(apiResponse.data.background_videos[0].video || "");
      setAudioKey(apiResponse.data.audio);
    }
  }, [apiResponse]);

  const handleSelectChange = (event) => {
    setVideoLoaded(false);
    const canvas = canvasRef.current;
    if (canvas && canvas.getContext) {
      console.log("checking for canvas if its exits");
      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;
      ctx.clearRect(0, 0, width, height);
    }
    setSelectedOption(event.target.value);
    setVideoData(event.target.value);
    const selectedValue = event.target.value;
    const selectedData = options.find((data) => data.video === selectedValue);
    setVideoIdData(selectedData.id);
    videoRef.current.load();
    setIsPlaying(false);
    const audio = audioRef.current;
    audio.pause();
    clearInterval(intervalElement);
    audio.currentTime = 0;
  };

  const logOutFn = () => {
    toast.success("logout successfully", {
      position: "top-center",
    });
    setTimeout(() => {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    }, 1000);
  };

  const getVideoData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/get-video-details/${videoId}`,
        {
          headers: { token: `${localStorage.getItem("accessToken")}` },
        }
      );

      console.log("check for status", response.data);
      if (response.data.status) {
        if (response.data.data.video_status == 1) {
          // clearInterval(apiRef.current);
          await connectSocket();
          setOptions(response.data.data.background_videos || []);
          setAudioFile(response.data.data.audio_path || "");
          setWords(response.data.data.subtitles || []);
          setSelectedOption(response.data.data.background_videos || "");
          if (response.data.data.id == 2) {
            setVideoData(response.data.data.background_videos[1].video || "");
            setSelectedOption(options[1].video);
          } else if (response.data.data.id == 3) {
            setVideoData(response.data.data.background_videos[2].video || "");

            setSelectedOption(options[2].video);
          } else {
            setVideoData(response.data.data.background_videos[0].video || "");
            setSelectedOption(options[0].video);
          }
        } else if (response.data.data.video_status == 4) {
          setProgress("waiting");
          setOptions(response.data.data.background_videos || []);
          setAudioFile(response.data.data.audio_path || "");
          setWords(response.data.data.subtitles || []);
          setSelectedOption(response.data.data.background_videos || "");
          if (response.data.data.id == 2) {
            setVideoData(response.data.data.background_videos[1].video || "");
            setSelectedOption(options[1].video);
          } else if (response.data.data.id == 3) {
            setVideoData(response.data.data.background_videos[2].video || "");
            setSelectedOption(options[2].video);
          } else {
            setVideoData(response.data.data.background_videos[0].video || "");
            setSelectedOption(options[0].video);
          }
        } else if (response.data.data.video_status == 3) {
          clearInterval(apiRef.current);
          setProgress("");
          toast.error("Video making failed", {
            position: "top-center",
          });
          setOptions(response.data.data.background_videos || []);
          setAudioFile(response.data.data.audio_path || "");
          setWords(response.data.data.subtitles || []);
          setSelectedOption(response.data.data.background_videos || "");
          if (response.data.data.id == 2) {
            setVideoData(response.data.data.background_videos[1].video || "");
            setSelectedOption(options[1].video);
          } else if (response.data.data.id == 3) {
            setVideoData(response.data.data.background_videos[2].video || "");
            setSelectedOption(options[2].video);
          } else {
            setVideoData(response.data.data.background_videos[0].video || "");
            setSelectedOption(options[0].video);
          }
        } else {
          setOptions(response.data.data?.background_videos || []);
          setAudioFile(response.data.data?.audio_path || "");
          setWords(response.data.data.subtitles || []);
          setSelectedOption(response.data.data?.background_videos || "");
          if (response.data.data.id == 2) {
            setVideoData(response.data.data.background_videos[1].video || "");
            setSelectedOption(options[1]?.video);
          } else if (response.data.data.id == 3) {
            setVideoData(response.data.data?.background_videos[2].video || "");

            setSelectedOption(options[2]?.video);
          } else {
            setVideoData(response.data.data?.background_videos[0].video || "");
            setSelectedOption(options[0]?.video);
          }
        }
      } else {
        toast.error(response.data.message, {
          position: "top-center",
        });

        setApiLoader(false);
        navigate("/");
      }
    } catch (error) {
      console.error("Error while fetching video details:", error.message);
      setLoader(false);
      setProgress("");
    }
  };

  useEffect(() => {
    if (videoId) {
      getVideoData();
    }
  }, [videoId]);

  const handlePlay = useCallback(() => {
    const video = videoRef.current;
    const audio = audioRef.current;
    if (video && audio) {
      video.play();
      audio.play().catch((err) => console.error("Audio playback failed:", err));
      setIsPlaying(true);
      setIsSubtitleVisible(true);
    }
  }, [videoRef, audioRef]);

  useEffect(() => {
    if (videoData && audioFile) {
      const video = document.createElement("video");
      video.setAttribute("muted", true);
      const videoSource = document.createElement("source");
      videoSource.setAttribute("src", videoData);
      videoSource.setAttribute("type", "video/mp4");
      video.appendChild(videoSource);

      const audio = document.createElement("audio");
      const audioSource = document.createElement("source");
      audioSource.setAttribute("src", audioFile);
      audioSource.setAttribute("type", "audio/mp3");
      audio.appendChild(audioSource);

      videoRef.current = video;
      audioRef.current = audio;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Define the device pixel ratio
      const dpr = 1;

      console.log("dpr", dpr);

      video.addEventListener("loadedmetadata", () => {
        canvas.width = video.videoWidth * dpr;
        canvas.height = video.videoHeight * dpr;
        ctx.scale(dpr, dpr);
        ctx.setTransform(dpr, 0, 0, dpr, 0, 0);
      });

      video.addEventListener("loadedmetadata", () => {
        setVideoLoaded(true);
        if (canvas && ctx) {
          video.currentTime = 0;
          video.addEventListener(
            "seeked",
            () => {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(
                video,
                0,
                0,
                canvas.width / dpr,
                canvas.height / dpr
              );
            },
            { once: true }
          );
        }
      });

      video.load();

      const drawCanvas = () => {
        if (videoRef.current && ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            videoRef.current,
            0,
            0,
            canvas.width / dpr,
            canvas.height / dpr
          );

          if (!audioRef.current.paused) {
            const currentTime = audioRef.current.currentTime;

            const subtitle = words.find(
              (word) =>
                parseFloat(word.start) <= currentTime &&
                parseFloat(word.end) >= currentTime
            );

            if (subtitle) {
              drawSubtitle(subtitle.text);
            } else {
              // drawSubtitle("");
            }
          } else {
            // drawSubtitle("");
          }
        }
        requestAnimationFrame(drawCanvas);
      };

      if (setIsSubtitleVisible) {
        drawCanvas();
        synchronizeSubtitles();
      }
    }
  }, [videoData, audioFile]);

  const synchronizeSubtitles = () => {
    const audio = audioRef.current;
    if (!audio) return;

    // Adjust subtitles to handle cases where start === end
    const adjustedSubtitles = [];
    for (let i = 0; i < words.length; i++) {
      const current = words[i];

      if (current.start === current.end) {
        if (i + 1 < words.length) {
          words[i + 1].text = `${current.text} ${words[i + 1].text}`;
        }
      } else {
        adjustedSubtitles.push(current);
      }
    }

    const updateSubtitle = () => {
      const currentTime = audio.currentTime;
      const subtitle = adjustedSubtitles.find(
        (word) =>
          parseFloat(word.start) <= currentTime &&
          parseFloat(word.end) >= currentTime
      );

      if (subtitle) {
        drawSubtitle(subtitle.text);
      } else {
        // drawSubtitle("");
      }

      if (audio.paused || audio.ended) {
        clearInterval(intervalElement);
      }
    };

    intervalElement = setInterval(updateSubtitle, 100);

    audio.addEventListener("pause", () => {
      clearInterval(intervalElement);
      console.log("Interval cleared because audio is paused.");
    });

    audio.addEventListener("play", () => {
      intervalElement = setInterval(updateSubtitle, 100);
      console.log("Interval restarted because audio is playing.");
    });

    audio.addEventListener("ended", () => {
      clearInterval(intervalElement);
      console.log("Audio ended event triggered.");
      if (!videoRef.current.ended) {
        console.log("Restarting video and audio for synchronization.");
        videoRef.current.currentTime = 0;
        audioRef.current.currentTime = 0;
        videoRef.current.play();
        audioRef.current.play();
      }
    });
  };

  const drawSubtitle = (text) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const height = canvas?.height;
    const width = canvas?.width;

    if (ctx && height && width) {
      const fontSize = Math.floor(height * 0.06);
      ctx.font = `bold ${fontSize}px Arial, Verdana, Sans-serif`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const glowColor = "#fffaba"; // Neon glow color (light yellow)
      const mainTextColor = "#fff454"; // Main text color (yellow)

      // First, apply the glowing shadow by layering multiple shadows
      const lineHeight = fontSize * 1.2;
      const maxWidth = width * 0.9;

      const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
        const words = text.split(" ");
        let line = "";
        let lines = [];
        for (let i = 0; i < words.length; i++) {
          const testLine = line + words[i] + " ";
          const testWidth = ctx.measureText(testLine).width;
          if (testWidth > maxWidth && i > 0) {
            lines.push(line);
            line = words[i] + " ";
          } else {
            line = testLine;
          }
        }
        lines.push(line);
        return lines;
      };

      const x = width / 2;
      const y = height / 2; // Adjust vertical position as needed

      // Layering shadows for the glowing effect (like the CSS text-shadow)
      ctx.lineWidth = Math.floor(fontSize * 0.1);

      // Draw glowing text (shadows)
      ctx.fillStyle = glowColor;
      for (let i = 0; i < 5; i++) {
        const blurAmount = 150 + i * 150; // Increase blur for each layer
        const offsetY = Math.sin(((i - 10) * Math.PI) / 20) * 15; // Fade effect for Y offset (top to bottom)
        const offsetX = Math.cos(((i - 10) * Math.PI) / 20) * 15; // Fade effect for X offset (left to right)
        ctx.shadowColor = glowColor;
        ctx.shadowBlur = blurAmount;
        ctx.shadowOffsetX = offsetX;
        ctx.shadowOffsetY = offsetY;

        const lines = wrapText(ctx, text, x, y, maxWidth, lineHeight);
        for (let i = 0; i < lines.length; i++) {
          const yOffset = y - (lines.length / 2 - i) * lineHeight;
          ctx.fillText(lines[i].trim(), x, yOffset);
        }
      }

      // Additional glow effect (border + shadow)
      ctx.fillStyle = mainTextColor; // Main text color (yellow)
      ctx.shadowColor = "black"; // Add black shadow
      ctx.shadowBlur = 10; // Subtle shadow blur
      ctx.shadowOffsetX = 5; // Slight X offset for the shadow
      ctx.shadowOffsetY = 5; // Slight Y offset for the shadow

      const lines = wrapText(ctx, text, x, y, maxWidth, lineHeight);
      for (let i = 0; i < lines.length; i++) {
        const yOffset = y - (lines.length / 2 - i) * lineHeight;
        ctx.fillText(lines[i].trim(), x, yOffset);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      drawSubtitle("Your Subtitle Text Here");
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleStop = () => {
    const video = videoRef.current;
    const audio = audioRef.current;

    if (video && audio) {
      video.pause();
      audio.pause();

      setIsPlaying(false);
    }
  };

  const downloadVideo = async (videoUrl, vId) => {
    console.log("video url", videoUrl);
    try {
      setLoader(true);
      const response = await fetch(videoUrl, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.setAttribute("download", "sample-video.mp4");
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(blobUrl);
      if (videoId == vId) {
        setProgress("");
      }
      setLoader(false);
    } catch (error) {
      console.error("Failed to download video:", error);
      setLoader(false);
      if (videoId == vId) {
        setProgress("");
      }
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/video-download`,
        {
          video_id: videoId,
          background_video_id: videoIdData,
          audio: audioKey,
        },
        {
          headers: {
            token: localStorage.getItem("accessToken"),
          },
        }
      );
      if (response.data.status) {
        console.log("response", response);

        navigate(`/result?videoId=${response.data.data.video_id}`);
        if (response.data.data.video_status == 0) {
          await getVideoData();
        } else if (response.data.data.video_status == 1) {
          await connectSocket();
        } else if (response.data.data.video_status == 4) {
          await connectSocket();
          // apiRef.current = setInterval(() => {
          //   getVideoData();
          // }, 5000);
        } else if (response.data.data.video_status == 2) {
          downloadVideo(response.data.data.videoUrl);
        }
      }
    } catch (error) {
      setProgress("");
      console.log("error while downloading video", error.message);
    }
  };

  const connectSocket = async () => {
    setProgress("waiting");
    const socket = new WebSocket(
      `${process.env.REACT_APP_API_ENDPOINT.replace(
        /^http/,
        "ws"
      )}/process-video`
    );

    socket.addEventListener("open", () => {
      if (videoId) {
        socket.send(JSON.stringify({ video_id: videoId }));
      } else {
        console.error("Error: video_id is not defined.");
      }
    });

    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "video_progress") {
        if (videoId == data.videoId) {
          setProgress(data.progress);
        }
        console.log("video data inside the socket", data.videoId);
      } else if (data.type === "video_progress_failed") {
        if (videoId == data.videoId) {
          setProgress("");
        }

        console.error("Video processing failed.");
      } else if (data.type === "video_progress_complete") {
        if (videoId == data.videoId) {
          downloadVideo(data.downloadUrl, data.videoId);
        }
      }
    });

    socket.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
      setProgress("");
    });

    socket.addEventListener("close", () => {
      console.log("WebSocket connection closed.");
      setProgress("");
    });
  };

  useEffect(() => {
    return () => {
      clearInterval(subtitleTimerRef.current);
    };
  }, []);

  return (
    <>
      <Container className="py-4">
        <ToastContainer />
        <nav className="mb-5 d-flex justify-content-between align-items-center">
          <h1
            className="fw-bold responsive_header"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setApiLoader(false);
              navigate("/");
            }}
          >
            BrainNumb <span className="text-secondary fw-light">AI Notes</span>
          </h1>

          <div>
            {localStorage.getItem("accessToken") ? (
              <Button className="nav_button_custom_color" onClick={logOutFn}>
                Logout
              </Button>
            ) : (
              <Button
                className="nav_button_custom_color"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>
            )}
          </div>
        </nav>
        <Row className=" responsive_handling_block align-items-center top_margin">
          <Col
            md={6}
            lg={4}
            className=" d-flex justify-content-center mb-4 mb-md-0"
          >
            <div className="image_main position-relative">
              <canvas
                id="textCanvas"
                ref={canvasRef}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                }}
              ></canvas>
              {!isPlaying ? (
                <button
                  id="playButton"
                  onClick={handlePlay}
                  style={{
                    display: videoLoaded ? "flex" : "none",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "20px 20px",
                    background: "rgba(0, 0, 0, 0.7)", // Semi-transparent black background
                    color: "white", // White color for icon
                    border: "none",
                    borderRadius: "50%", // Make the button circular
                    cursor: "pointer",
                    fontSize: "24px", // Larger icon size
                    zIndex: 10,
                    opacity: 0.8,
                    transition: "opacity 0.3s ease, transform 0.3s ease",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.opacity = 1)}
                  onMouseOut={(e) => (e.currentTarget.style.opacity = 0.8)}
                >
                  <FaPlay />
                </button>
              ) : (
                <button
                  id="playButton"
                  onClick={handleStop}
                  style={{
                    display: videoLoaded ? "flex" : "none",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    border: "none",
                    borderRadius: "50%", // Make the button circular
                    cursor: "pointer",
                    fontSize: "24px", // Larger icon size
                    zIndex: 10,
                    opacity: 0, // Initially hidden
                    transition: "opacity 0.3s ease, transform 0.3s ease", // Smooth transition for opacity
                    alignItems: "center", // Vertically center the icon
                    justifyContent: "center", // Horizontally center the icon
                    padding: "20px 20px",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.opacity = 1)} // Increase opacity on hover
                  onMouseOut={(e) => (e.currentTarget.style.opacity = 0)} // Reset opacity on mouse out
                >
                  <CiPause1 size={30} color="white" />{" "}
                </button>
              )}
            </div>
          </Col>
          <Col md={6} className="text-center text-md-start">
            <h2 className="mb-3 hero_heading_text">
              Your brainrot video is ready to share
            </h2>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Choose Video background</Form.Label>
              <Form.Select
                disabled={progress}
                value={selectedOption}
                onChange={handleSelectChange}
                aria-label="Select an option"
              >
                {options.map((data, index) => (
                  <option key={index} value={data.video}>
                    {data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <div className="d-flex mt-4  ">
              {!progress ? (
                <Button
                  variant="secondary"
                  className="py-3 px-4 nav_button_custom_color me-3  d-flex justify-content-center align-items-center"
                  onClick={handleDownload}
                  disabled={loader}
                >
                  {!loader ? (
                    <span>
                      <FaDownload className="me-2" />
                      Download Video
                    </span>
                  ) : (
                    <span>
                      <Spinner size="sm" className="me-2" />
                      Downloding
                    </span>
                  )}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  className="py-3 px-4 nav_button_custom_color me-3 d-flex justify-content-center align-items-center"
                  disabled
                >
                  {progress == "waiting" ? (
                    <span>
                      <FaHourglass className="spin" /> Making...
                    </span>
                  ) : (
                    `Processing ${progress}%`
                  )}
                </Button>
              )}
              <Button
                variant="secondary"
                className="py-3 px-4 nav_button_custom_color"
                onClick={(e) => {
                  e.preventDefault();
                  setApiLoader(false);
                  navigate("/");
                }}
              >
                <IoReload className="me-2" />
                Make Another
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResponsePage;
